import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FirebaseCategory, FirebaseProduct } from '../../../firebase/functions';
import { MenuProductsSectionLoader } from './Loader';
import { UIContainer } from '../../UI';
import MenuNewChangeItemsType from '../NewChangeItemsType';
import MenuItemsGroup from '../Items/Group';
import { screenSizes } from '../../../utils/screensSizes';

export const MenuProductsSection = () => {
  const [loading, setLoading] = useState(false);
  const isLargeScreen = useMediaQuery({ query: screenSizes.lg });
  const [promotionalProducts, setPromotionalProducts] = useState([]);
  const [categoriesWithProducts, setCategoriesWithProducts] = useState([]);

  useEffect(() => {
    setLoading(true);
    async function init() {
      const categoriesData = await FirebaseCategory.getAllCategories();
      const productsData = await FirebaseProduct.getAllProducts();

      const promotionalProds = productsData.filter(({ promotionalPrice, variations }) => {
        const hasPromotionalPriceOnVariations = variations.some(
          ({ promotionalPrice: varPromotionalPrice }) => (
            varPromotionalPrice && Number(varPromotionalPrice)
          ),
        );

        return (
          (promotionalPrice && Number(promotionalPrice) > 0)
            || hasPromotionalPriceOnVariations
        );
      });

      const customCategoriesWithProds = categoriesData.map((category) => {
        const productsOnThisCategory = productsData
          .filter(({ categoryId }) => categoryId === category.id);
        return (
          {
            ...category,
            products: productsOnThisCategory,
          }
        );
      });

      setPromotionalProducts(promotionalProds);
      setCategoriesWithProducts(customCategoriesWithProds);
      setLoading(false);
    }
    init();
  }, []);

  if (loading) {
    return <MenuProductsSectionLoader />;
  }

  return (
    <>
      <MenuNewChangeItemsType
        customOffset={-120}
        categoriesWithProducts={categoriesWithProducts}
        hasPromotionalItems={promotionalProducts.length > 0}
        optionsContainerId={isLargeScreen ? 'desktop-menu' : ''}
      />

      <UIContainer>
        <MenuItemsGroup
          promotionalProducts={promotionalProducts}
          categoriesWithProducts={categoriesWithProducts}
        />
      </UIContainer>
    </>
  );
};
