import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Menu from '../../components/Menu';
import Cart from '../../components/Cart';
import { screenSizes } from '../../utils/screensSizes';

export const ScreensMenu = () => {
  const isLargeScreen = useMediaQuery({ query: screenSizes.lg });

  if (isLargeScreen) {
    return (
      <div className="tw-flex tw-w-full tw-max-h-screen tw-overflow-hidden">
        <div
          id="desktop-menu"
          style={{ width: 'calc(100% - 500px)' }}
          className="tw-flex tw-flex-col tw-overflow-y-auto"
        >
          <Menu />
        </div>
        <div
          style={{ width: '500px' }}
          className="tw-relative tw-flex tw-flex-col tw-overflow-x-auto"
        >
          <Cart />
        </div>
      </div>
    );
  }

  return (
    <div>
      <Menu />
    </div>
  );
};
